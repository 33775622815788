import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Header from '../header/Header';
import Footer from '../footer/Footer';
import MainPage from '../pages/MainPage';
import SupportUAPage from '../pages/SupportUAPage';
import Page404 from '../pages/Page404';

function App() {
	return (
		<Router>
			<div className="pagebody">
				<Header/>
				<main className='main'>
					<Routes>
						<Route path="/" element={<MainPage/>} />
						<Route path="/support-ukraine" element={<SupportUAPage/>} />
						<Route path="*" element={<Page404 />} />  
					</Routes>
				</main>
				<Footer/>
			</div> 
		</Router>
	);
}

export default App;

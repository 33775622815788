import { useState, useEffect } from 'react';
import './footer.scss';

const Footer = () => {

    const [year, setYear] = useState(null)

    useEffect(() => {
        let currentDate = new Date()
        let currentYear = currentDate.getFullYear()
        setYear(currentYear)
    })

    return (
        <footer className='footer'>
            <div className="container">
            <div className="copyright">
                <div className="copyright-createdby">Created with React and SCSS by Oleksandr Androshchuk</div>
                <span>copyright © {year} theandro.com</span>
            </div>
            </div>
        </footer>
    )
}

export default Footer;
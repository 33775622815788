import { Helmet } from "react-helmet";
import About from "../about/About";

const MainPage = () => {
    return (
        <>
            <Helmet>
                <meta 
                    name="description" 
                    content="A frontend developer crafts engaging user experiences through website and application design, employing languages like HTML, CSS, and JavaScript. They ensure seamless navigation, responsive layouts, and optimized performance, enhancing user interaction and satisfaction."/>
                <title>Oleksandr Androshchuk - Frontend / React Developer Based in Kyiv</title>
            </Helmet>
            <About/>
        </>
    )
}

export default MainPage;
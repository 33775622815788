import './supportUA.scss';

const SupportUA = () => {
    return (
        <div className="container">
            <div className="supportua">
                <h1>Support freedom, support true love, support Ukraine</h1>
                <div className="supportua-platform">
                    <span><strong>UNITED24</strong> – OFFICIAL FUNDRAISING PLATFORM OF UKRAINE</span>
                    <a className="btn" href="https://u24.gov.ua/" target="_blank" rel="noreferrer">Donate now</a>
                </div>
            </div>
            
        </div>
        
    )
}

export default SupportUA;
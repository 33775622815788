import { Helmet } from "react-helmet";
import SupportUA from "../support/SupportUA";

const SupportUAPage = () => {
    return (
        <>
            <Helmet>
                <meta 
                    name="description" 
                    content="Support freedom, support true love, support Ukraine"/>
                <title>Support Ukraine &#127482;&#127462;</title>
            </Helmet>
            <SupportUA/>
        </>
    )
}

export default SupportUAPage;
import { NavLink } from 'react-router-dom';

import './header.scss';

const Header = () => {
    return (
        <header className="header">
            <nav>
                <div className="menu">
                    <NavLink 
                        end
                        className={({ isActive }) => "menu__link" + (isActive ? " active" : "")}
                        to="/">Home</NavLink>
                    <NavLink 
                        end
                        className={({ isActive }) => "menu__link" + (isActive ? " active" : "")}
                        to="/support-ukraine">Support Ukraine &#127482;&#127462;</NavLink>
                </div>
            </nav>
        </header>
    )
}

export default Header;
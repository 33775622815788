import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import './page404.scss';

const Page404 = () => {
    return (
        <>
            <Helmet>
                <meta 
                    name="description" 
                    content="404 Page - reload the page"/>
                <title>404 Page</title>
            </Helmet>
            <div className="container">
                <div className="error404">
                    <div className="error404-title">Error 404</div>
                    <div className="error404-subtitle">Page not found</div>
                    <Link to="/" className="btn">To the Main page</Link>
                </div>
            </div>
        </>
        
    )
}

export default Page404;